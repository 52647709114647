import React, { useEffect, useState } from "react";
import Logo from '../../assets/images/logo.png'
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { appConfig } from "../../appConfig";
import { footerData } from "./footerData";
import ReactWhatsappButton from "react-whatsapp-button";
import PAY from "../../assets/images/banner/pay.png";
import ICON from "../../assets/images/logo.png";
import TPP from "../../assets/images/banner/tpp.png";
import IATA from "../../assets/images/banner/iata.png";
import { Check, Facebook, Linkedin, Mail, Phone, Printer, Twitter } from "react-feather";
import googleplus from "../../assets/images/icons/icons8-google-plus-50.png";
import pinterrest from "../../assets/images/icons/icons8-pinterest-50.png";
import cards from "../../assets/images/icons/cards.png";
import bclays from "../../assets/images/icons/baclays-power.jpg";


const Footer=props=>{

    const headRoute = appConfig.headRoute
    const [pkgtype,setpkgtype]=useState()
    const location=useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    
  useEffect(() => {
    
  const catId = searchParams.get("category");
  const pt = searchParams.get("type");
  setpkgtype(pt);
 
  }, [location.pathname,searchParams]);


  const bulletList=[
    `Discounted Flights`,
    `International Flights Sale`,
    `Cheap-Flight-Offers`,
    `Christmas Flights Sale`,
    `Easter Flights Sale`,
    `Summer Flights Sale`,
    `New Year Flights Sale`,
  ]

  const getNo=()=>{
    if(pkgtype=='umrah') 
      return  <ReactWhatsappButton
    countryCode="44"
    phoneNumber="7728431679"
/>
      else if(pkgtype=='holiday') 
      return <ReactWhatsappButton
      countryCode="44"
      phoneNumber="7463493185"
  />
    else
      return <ReactWhatsappButton
      countryCode="44"
      phoneNumber="7365892753"
  />
  }
  const getNo2=()=>{
    if(pkgtype=='umrah') 
      return  "7728431679"

      else if(pkgtype=='holiday') 
      return"7463493185"

    else
      return "7365892753"

  }

    return(
        
        <div className="bg-[#20212d] ">
          <div className=" mx-auto w-[95%] md:w-[90%] grid grid-cols-1 md:grid-cols-4 py-5">
            <div className="flex flex-col justify-center items-center">
            <img src={ICON} alt="Alliance Travels Logo" className="h-[50px] mb-4" />
            <div className="flex justify-center gap-2">
              <div className="bg-white rounded-full h-[30px] w-[30px] flex justify-center items-center text-black"><Facebook className="h-[20px] w-[20px]" /></div>
              <div className="bg-white rounded-full h-[30px] w-[30px] flex justify-center items-center text-black"><Twitter className="h-[20px] w-[20px]"/></div>
              <div className="bg-white rounded-full h-[30px] w-[30px] flex justify-center items-center text-black"><Linkedin className="h-[20px] w-[20px]"/></div>
              <div className="bg-white rounded-full h-[30px] w-[30px] flex justify-center items-center"><img className="h-[20px] w-[20px]" src={googleplus}/></div>
              <div className="bg-white rounded-full h-[30px] w-[30px] flex justify-center items-center"><img className="h-[20px] w-[20px]" src={pinterrest}/></div>
            </div>
            </div>
            <div className="col-span-3 mt-4">
            <p className="text-white tracking-[1px] text-justify">Find cheap flight tickets at lowest airfare at Travelelink to worldwide destinations from all major airports of UK on all major airlines. We try to ensure your travel within your budget!. Expert Customer Service & more than 750 Airlines Deals.</p>
            </div>

          </div>
            <hr className="my-3 mx-[5px] border-t-1 border-white w-[100%] "/>

            <div className=" mx-auto w-[95%] md:w-[90%] bg-black flex justify-around items-center p-4">
              <div className="flex items-center gap-3">
                <div className="bg-blue-700 rounded-full h-[35px] w-[35px] md:h-[50px] md:w-[50px] flex justify-center items-center"><Phone className="text-white"/></div>
                <div>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] font-bold">Phone</p>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] ">{getNo2()}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="bg-blue-700 rounded-full h-[35px] w-[35px] md:h-[50px] md:w-[50px] flex justify-center items-center"><Mail className="text-white"/></div>
                <div>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] font-bold">Email</p>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] ">info@alliancetravel.co.uk</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="bg-blue-700 rounded-full h-[35px] w-[35px] md:h-[50px] md:w-[50px] flex justify-center items-center"><Printer className="text-white"/></div>
                <div>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] font-bold">Fax</p>
                  <p className="text-white text-[10px] md:text-[14px] tracking-[1px] ">{getNo2()}</p>
                </div>
              </div>
            </div>

            <hr className="my-3 mx-[5px] border-t-1 border-white w-[100%] "/>

            <div className="mx-auto w-[95%] md:w-[90%] grid grid-cols-1 md:grid-cols-3">
            <div className=" col-span-2 flex flex-col justify-around items-center p-4">
            <div className="flex flex-wrap justify-center gap-3 mb-3">
              {
                bulletList.map(b=>{
                  return <div className="flex gap-1"><Check className="text-[#40E0D0] h-[14px]"/><p  className="text-white text-xs hover:text-gray-300 transition-colors duration-300"> {b}</p></div>
                })
              }
              
            

          </div>

          <div className="flex gap-1 items-center justify-center mt-5">
            <img src={cards} className="max-w-[30%]"/>
            <img src={bclays} className="max-w-[12%]"/>
          </div>
          <p className="text-white text-center mt-3">Booking Fee From £15-£25 Terms & Conditions apply.</p>
          <p className="text-white text-center mt-1">Prices are subject to availability of seats. Availability of seats within date range is very limited. Call now and check with our Flight Consultant.</p>
          
          <div>
            
          </div>
            </div>

            <div className="space-y-1 mt-3">
              <p className="text-lg tracking-[1px] text-white">Get Flight Deals via Newsletter</p>
              <p className=" text-xs text-white">Enter your email address</p>
              <input type="text" className="bg-black text-white border-black p-1 text-sm rounded-md w-full"/>
              <p className=" text-[9px] text-white">*We Never Send Spam</p>
              <button className=" text-white bg-blue-600 p-2 rounded-md mt-2">Subscribe</button>
            </div>
            </div>

            <div className="w-full bg-black flex justify-center md:justify-between items-center px-2 py-2 md:px-10 md:py-5 mt-5">
            <p className="text-white text-[10px] lg:text-md text-center">
              Copyright © 2024 Alliance Travels. All rights reserved.
            </p>
            <div className="space-x-3">
           <a href="/" className="text-white text-xs hover:text-gray-300 transition-colors duration-300"> Home</a>
             <a href="/traveltips" className="text-white text-xs hover:text-gray-300 transition-colors duration-300">TravelTips</a>
             <a href="/tc" className="text-white text-xs hover:text-gray-300 transition-colors duration-300">Terms & Conditions</a>
             <a href="/privacypolicy" className="text-white text-xs hover:text-gray-300 transition-colors duration-300">Privacy Policy</a>
             <a href="/aboutus" className="text-white text-xs hover:text-gray-300 transition-colors duration-300">FAQ</a>
            </div>
            </div>

            {
              getNo()
            }

    
</div>

    )
}
export default Footer
import React, { useEffect, useState } from "react";


import AOS from 'aos';
import 'aos/dist/aos.css';

import { Circle, Facebook, Linkedin, Phone, Twitter } from "react-feather";

import Banner from '../../assets/images/blog/user-img-1.jpg';
import tables from '../../assets/images/blog/tables.jpg';
import img3 from '../../assets/images/blog/blog-titleimg.jpg';

import Image from '../Contact/Image';
import Footer from "../footer";

import HeadingSep from "../../assets/images/banner/heading-sep.png";

import service from "../../Service/Service";
import CustomizeSection from "../Home3/CustomizeSection";
import Navbar from "../navbar";
import InputSection from "../Home3/InputSection";
import { Link } from "react-router-dom";
import CategorySection from "../Home3/CategorySection";
import CardsSection from "../Home3/CardsSection";
import { useSearchParams } from 'react-router-dom';
import CategorySectionUmrah from "./CatSection";
import BannerSection from "../Home3/BannerSection";
const TopSection=props=>{
  const [categories,setCategories]=useState([])
  const [packages,setPackages]=useState([])
  const [airportDep,setAirportDep]=useState([])
  const [airportArv,setAirportArv]=useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const catId = searchParams.get("category");
  const pkgtype = searchParams.get("type");
const getCategories=()=>{
  


service.getAllCategory().then(response=>{
  const search = window.location.search;
  const params = new URLSearchParams(search);
  
   

   
    
    let cat= response.data.filter(x=>x.uid==catId)
  setCategories(response.data)
})
}

const getPackages=()=>{
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  
  //   let catId=params.get('category');
  // service.getAllpackageByCatIdUmrah({category_id:catId}).then(response=>{
  //    let ff= pkgtype=='holiday'?'Holiday':'Umrah'
  //    let filtered= response.data.filter(x=>x.package_type==ff)
  //   setPackages(filtered)
  // })
  service.getAllpackageUmrah().then(response=>{
    let ff= pkgtype=='holiday'?'Holiday':'Umrah'
    let filtered= response.data.filter(x=>x.package_type==ff)
   setPackages(filtered)
   var scrollDiv = document.getElementById("catsec").offsetTop;
window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
 })
  }


    useEffect(()=>{
        getCategories();
        getPackages();
      
    },[])

    useEffect(()=>{
      getCategories();
      getPackages();
    
  },[catId,pkgtype])

    return(<div>
      <Navbar bg={0}/>
      <BannerSection/>

<div id="catsec"></div>

<div className="mt-[40px] p-10">
  {
    categories.map(x=>{
      return <CategorySectionUmrah data={x} packages={packages}/>
    })
  }
</div>
{/* <CustomizeSection/> */}
<Footer/>



</div>
    )
}


export default TopSection